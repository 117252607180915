import React from 'react';
import {Autocomplete, Button, styled, TextField} from "@mui/material";
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import {v4 as uuidv4} from 'uuid';
import {
  ErrorMessages, initialAmmoValue,
  initialTargetValue,
  mgrsCoordinatesRegExp,
  objectiveOptions,
  Objectives,
  Results
} from "../const";
import {useFormik} from "formik";
import {addMinutes, format, parse} from "date-fns";
import TargetInput from "./TargetInput";
import {removeAtIndex} from "../utils/arrayUtils";
import {AmmoForm, Report} from "../types";
import AmmoInput from "./AmmoInput";

type CreateReportFormValuesType = Pick<Report, 'objective' | 'targets'> & {
  startTime: string;
  endTime: string;
  ammos: AmmoForm[];
};

const initialValues = {
  startTime: '',
  endTime: '',
  objective: objectiveOptions[0],
  targets: [
    {
      value: initialTargetValue,
      description: '',
    }
  ],
  ammos: [initialAmmoValue],
}

const hhmmRexExp = new RegExp(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)

const createReportSchema = yup.object().shape({
  startTime: yup.string().required(ErrorMessages.Required).matches(hhmmRexExp, { message: ErrorMessages.HHmm}),
  endTime: yup.string().required(ErrorMessages.Required).matches(hhmmRexExp, { message: ErrorMessages.HHmm}),
  objective: yup.string().required(ErrorMessages.Required),
  targets: yup.array().of(yup.object({
    value: yup.string().required(ErrorMessages.Required).matches(mgrsCoordinatesRegExp, { message: 'Ціль має бути у форматі MGRS' }),
    description: yup.string(),
  })),
  ammos: yup.array().of(yup.object({
    ammoType: yup.string(),
    ammoQuantity: yup.string(),
    detonator: yup.string(),
    detonatorQuantity: yup.string(),
    initiator: yup.string(),
    initiatorQuantity: yup.string(),
  })),
});

interface CreateReportFormProps {
  onSubmit: (report: Report) => void;
}

const CreateReportForm: React.FC<CreateReportFormProps> = ({ onSubmit }) => {

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik<CreateReportFormValuesType>({
    initialValues,
    validationSchema: createReportSchema,
    onSubmit: (values, { resetForm }) => {
      let startDate, endDate;
      try {
        startDate = parse(values.startTime, 'HH:mm', new Date());
        endDate = parse(values.endTime, 'HH:mm', new Date());
      }catch(e){
      }

      onSubmit({
        ...values,
        id: uuidv4(),
        startTime: startDate || new Date(),
        endTime: endDate || new Date(),
        ammos: values.ammos.map((ammo) => ({
          ...ammo,
          ammoQuantity: parseInt(ammo.ammoQuantity) || undefined,
          detonatorQuantity: parseInt(ammo.detonatorQuantity) || undefined,
          initiatorQuantity: parseInt(ammo.initiatorQuantity) || undefined,
        })),
        results: [values.objective === Objectives.Hit ? Results.Hit : Results.Executed],
      });
      resetForm();
    },
  });

  const isAmmoFieldsDisabled = values.objective === Objectives.HumanitarianCargo;

  const handleChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFieldValue('startTime', val);
    try{
      const startDate = parse(val, 'HH:mm', new Date());
      const endDate = addMinutes(startDate, 20);
      setFieldValue('endTime', format(endDate, 'HH:mm'));
    }catch(e){
    }
  }

  const addTarget = () => {
    setFieldValue('targets', [...values.targets, { value: initialTargetValue, description: ''}]);
  }

  const removeTarget = (index: number) => {
    setFieldValue('targets', removeAtIndex(values.targets, index));
  }

  const addAmmo = () => {
    setFieldValue('ammos', [...values.ammos, initialAmmoValue]);
  }

  const removeAmmo = (index: number) => {
    setFieldValue('ammos', removeAtIndex(values.ammos, index))
  }

  const handleChangeObjective = (value: string) => {
    if(value === Objectives.HumanitarianCargo){
      setFieldValue('detonators', []);
      setFieldValue('detonatorQuantity', '');
      setFieldValue('initiators', []);
      setFieldValue('initiatorQuantity', '');
      setFieldValue('ammos', []);
      setFieldValue('ammoQuantity', '');
    }
    setFieldValue('objective', value);
  };

  return <StyledForm onSubmit={handleSubmit}>
    <InputMask
      mask="99:99"
      value={values.startTime}
      onChange={handleChangeStartTime}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete='off'
        label="Час"
        name="startTime"
        error={touched.startTime && Boolean(errors.startTime)}
        helperText={touched.startTime && errors.startTime}
      />}
    </InputMask>
    <InputMask
      mask="99:99"
      value={values.endTime}
      onChange={handleChange}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete='off'
        label="Час закінчення"
        name="endTime"
        error={touched.endTime && Boolean(errors.endTime)}
        helperText={touched.endTime && errors.endTime}
      />}
    </InputMask>
    <Autocomplete
      disableClearable
      value={values.objective}
      onChange={(e, value) => handleChangeObjective(value)}
      options={objectiveOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="objective"
        label="Мета"
        error={touched.objective && Boolean(errors.objective)}
        helperText={touched.objective && errors.objective}
      />}
    />
    {values.targets.map((target, i) => <TargetInput
        key={i}
        target={target.value}
        targetDescription={target.description || ''}
        setFieldValue={setFieldValue}
        index={i}
        isLast={values.targets.length === i + 1}
        addTarget={addTarget}
        removeTarget={() => removeTarget(i)}
        //@ts-ignore
        error={errors.targets?.[i]?.value}
        touched={touched.targets?.[i]?.value}
      />)}
    {!isAmmoFieldsDisabled && values.ammos.map((ammo, i) => <AmmoInput
      key={i}
      index={i}
      isLast={values.ammos.length === i + 1}
      addAmmo={addAmmo}
      removeAmmo={() => removeAmmo(i)}
      ammo={ammo}
      setFieldValue={(key: string, value: any) => setFieldValue(`ammos[${i}.${key}`, value)}
      handleChange={handleChange}
      touched={errors?.ammos?.[i]}
      errors={errors.ammos?.[i]}
    />)}
    <Button variant="contained" type="submit">Додати заявку</Button>
  </StyledForm>
};

export default CreateReportForm;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
