export enum ErrorMessages {
  Required =  'Обов\'язкове поле',
  HHmm = 'Має бути у форматі гг:хх',
  HHmmddMMyyyy = 'Має бути у форматі гг:хх, дд.мм.рррр',
};

export const configKey = 'config';
export const reportsKey = 'reports_v4';

export const mgrsCoordinatesRegExp = /^\d{2}[A-Z] [A-Z]{2} \d{5} \d{5}$/;

export enum ViewType {
  Reports = 'Reports',
  Config = 'Config',
}

export enum Detonators {
  StandBy = 'Штатний',
  MYV1 = 'МУВ1',
  AMA = 'АМ-А',
  MD2 = 'МД-2',
  MD5M = 'МД-5М',
  KD8A = 'КД-8А',
  EDP = 'ЕДП-Р',
}

export const detonatorOptions = Object.values(Detonators);

export enum Initiators {
  StandBy = 'Штатний',
  InerticShockWorker = 'Інерційний накольник',
  ShockWorker = 'Накольник',
  MUV1 = 'МУВ-1',
  Djonik = 'Джонік'
}

export const initiatorOptions = Object.values(Initiators);

// export const ammoOptions = ['ТМ-62', 'МОА-400', 'МОА-900', 'АЗБ-5М', 'ФАБ-400', 'ФАБ-900', 'ПФМ'];
export enum Ammos {
  TM62 = 'ТМ 62М',
  MOA400 = 'МОА-400',
  MOA900 = 'МОА-900',
  //AZB5M = 'АЗБ-5М',
  ZAB25C = 'ЗАБ-2.5С',
  FAB85 = 'ФАБ-8.5-А',
  OGB = 'ОГ-Б1',
  PFM = 'ПФМ',
}
export const ammoOptions = Object.values(Ammos);

type AmmoConfig = {
  [x: string]: {
    initiator: Initiators,
    detonator: Detonators,
  }
}
export const ammoConfig: AmmoConfig = {
  [Ammos.TM62]: {
    initiator: Initiators.InerticShockWorker,
    detonator: Detonators.MD2,
  },
  [Ammos.MOA400]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  [Ammos.MOA900]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  [Ammos.FAB85]: {
    initiator: Initiators.ShockWorker,
    detonator: Detonators.StandBy,
  },
  //[Ammos.AZB5M]: {
  //  initiator: Initiators.ShockWorker,
  //  detonator: Detonators.AMA,
  //},
  [Ammos.PFM]: {
    initiator: Initiators.StandBy,
    detonator: Detonators.StandBy,
  },
  [Ammos.ZAB25C]: {
    initiator: Initiators.MUV1,
    detonator: Detonators.MD5M,
  },
  [Ammos.OGB]: {
    initiator: Initiators.StandBy,
    detonator: Detonators.StandBy,
  }
}

export enum Objectives {
  HumanitarianCargo = 'Доставка провізії',
  HumanitarianCargo1 = 'Гуманітарний вантаж',
  Hit = 'Ураження',
  Mining = 'Мінування',
  MetalHed = 'Скидання металевих їжаків'
}

export const objectiveOptions = Object.values(Objectives);

export enum Results {
  Executed = 'Виконано',
  Hit = 'Уражено',
  Fired = 'Обстріляно',
  Destroyed = 'Знищено',
  Missed = 'Невлучання',
  NoDetonation = 'Без детонації',
  DontHit = 'Неуражено',
  Mined = 'Заміновано',
  EmergencyLanding = 'Аварійна посадка',
  Lost = 'Борт втрачено',
}
export const resultOptions = Object.values(Results);

export const initialTargetValue = '36T YT';
export const initialAmmoValue = {
  ammoType: '',
  ammoQuantity: '',
  detonator: '',
  detonatorQuantity: '',
  initiator: '',
  initiatorQuantity: '',
}
